import { service } from ".";

class AuthApi {
  Login(req) {
    return service.post(`login`, req);
  }
  Register(req) {
    return service.post(`register`, req);
  }
  Logout() {
    return service.post("logout");
  }
  Delete() {
    return service.post("deleteaccount");
  }
}

export const authApi = new AuthApi();
