import { createSlice } from "@reduxjs/toolkit";
// import { notificationEvent } from "../events/notification";

const initialState = {
  messages: [],
  status: "nothing",
};

const slice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addToQueue(state, { payload }) {
      state.messages.push(payload);
    },
    removeFromQueue(state, { payload }) {
      const filtered = state.messages.filter((el) => el.id !== payload);
      state.messages = filtered;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { addToQueue, setStatus, removeFromQueue } = slice.actions;

export const addToQueueAsync = (message) => (dispatch) => {
  dispatch(setStatus("loading"));

  dispatch(addToQueue(message));

  setTimeout(() => {
    dispatch(removeFromQueue(message.id));
  }, 1300);
  // dispatch(setCategories(response?.data?.data));
  // dispatch(setStatus("data"));
};

export const snackbarReducer = slice.reducer;
