import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../ProductPage/productPage.css";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { countries } from "countries-list";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartByUuidAsync,
  addToCartWithAuthenticationAsync,
} from "../../core/slices/cartSlice";
import { useAuth } from "../../hooks/useAuth";
import ProductCard from "./ProductCard";
import { service } from "../../core/services";

const countriesArrWithIs = Object.keys(countries).map((country) => ({
  ...countries[country],
  code: country,
}));

const countriesArr = countriesArrWithIs.filter(
  (el) => el.name.toLowerCase() !== "israel"
);

const AddButton = styled(Button)`
  background: #000;
  color: #fff;
  padding: 15px;
  width: 100%;
  border-radius: 0;
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const SearchInput = styled.input`
  background: none;
  border: none;
  border-bottom: 1px solid #000;
  width: 300px;
  outline: none;
  padding-bottom: 7px;
  font-size: 14px;
`;

function SearchPage() {
  const [productsData, setProductsData] = useState({});
  const [productsDataa, setProductsDataa] = useState({});
  const { categoryId } = useParams();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [sort, setSort] = useState("date-new-to-old");
  const [isCountryOpen, setCountryOpen] = useState(false);
  const [isAddContactOpen, setAddContact] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalProducts, setTotalProducts] = useState(null);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const { accessToken } = useAuth();
  const timeoutRef = useRef();
  const { companyInformation } = useSelector((state) => state.company);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Lebanon",
    native: "لبنان",
    phone: [961],
    continent: "AS",
    capital: "Beirut",
    currency: ["LBP"],
    languages: ["ar", "fr"],
    code: "LB",
  });

  useEffect(() => {
    if (companyInformation?.currency === "AED") {
      setSelectedCountry({
        name: "United Arab Emirates",
        native: "دولة الإمارات العربية المتحدة",
        phone: [971],
        continent: "AS",
        capital: "Abu Dhabi",
        currency: ["AED"],
        languages: ["ar"],
        code: "AE",
      });
    }
  }, [companyInformation?.currency]);

  const { i18n, t } = useTranslation();

  // const fetchProducts = useCallback(async () => {
  //   if (fetching) return;
  //   setFetching(true);
  //   setLoading(true);
  //   try {
  //     const apiUrl = `https://lb-app.kyveli.me/api/products/${categoryId}?page=${page}&sort=${sort}`;

  //     const response = await axios.get(apiUrl, {
  //       params: filters,
  //       headers: {
  //         "Accept-Language": i18n.language || "en",
  //       },
  //     });

  //     const newData = response.data.data;
  //     if (page === 1) {
  //       setProductsData({
  //         products: newData.products,
  //         product_count: newData.product_count,
  //         subcategory: newData.subcategory,
  //       });
  //     } else {
  //       setProductsData((prevData) => ({
  //         ...prevData,
  //         products: [...prevData.products, ...newData.products],
  //         subcategory: newData.subcategory,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching products: ", error);
  //   } finally {
  //     setLoading(false);
  //     setFetching(false);
  //   }
  // }, [categoryId, fetching, filters, i18n.language, page, sort]);

  // useEffect(() => {
  //   fetchProducts();
  // }, [categoryId, page, sort, filters, fetchProducts]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `https://lb-app.kyveli.me/api/color/${categoryId}`,
  //         {
  //           headers: {
  //             "Accept-Language": i18n.language || "en",
  //           },
  //         }
  //       );
  //       setProductsDataa(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [categoryId, i18n.language]);

  // const handleScroll = () => {
  //   if (productsData) {

  //     if (
  //       window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight
  //     ) {
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const addToCartAsync = async () => {
    const uuid = localStorage.getItem("cart_uuid");
    setAddToCartLoading(true);
    dispatch(
      addToCartByUuidAsync(
        {
          product_id: selectedProduct,
          quantity: 1,
          cart_uuid: uuid,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {
          setAddToCartLoading(false);
          setSelectedItem(null);
        }
      )
    );
  };

  const handleAddToCart = (product) => {
    if (!accessToken) {
      // save product locally
      const phoneNumber = localStorage.getItem("phoneNumber");
      if (!phoneNumber) {
        setAddContact(true);
      } else {
        addToCartAsync();
      }
    } else {
      const uuid = localStorage.getItem("cart_uuid");
      setAddToCartLoading(true);
      dispatch(
        addToCartWithAuthenticationAsync(
          {
            product_id: selectedProduct,
            quantity: 1,
            cart_uuid: uuid,
          },
          () => {
            setAddToCartLoading(false);
            setSelectedItem(null);
          },
          () => {
            setAddToCartLoading(false);
          }
        )
      );
    }
  };

  const handleSearchInput = (e) => {
    setValue(e.target.value);
    setProductsData({ products: null });
    setPage(1);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `https://lb-app.kyveli.me/api/search?application_id=${companyInformation?.application_id}&table_name=${companyInformation?.search_table_name}&search_term=${e.target.value}&page=${page}`,
          {
            headers: {
              Authorization:
                "Bearer 41|KW8lpiz9cn265kYGDrKUOjmyT5atm9xb1asKUFcJ71efdadb",
              Currency: localStorage.getItem("currency"),
            },
          }
        );
        setProductsData({ products: res.data.data.products });
        setTotalProducts(res.data.data.total);
        console.log(res.data.data);
      } catch (e) {
        console.log("error", e);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const handleScroll = useCallback(async () => {
    if (!loading && totalProducts !== productsData?.products?.length) {
      if (
        window.innerHeight + Math.round(window.scrollY) + 1000 >=
        document.body.offsetHeight
      ) {
        setPage((prev) => prev + 1);
        try {
          setLoading(true);
          const res = await axios.get(
            `https://lb-app.kyveli.me/api/search?application_id=${companyInformation?.application_id}&table_name=${companyInformation?.search_table_name}&search_term=${value}&page=${page}`,
            {
              headers: {
                Authorization:
                  "Bearer 41|KW8lpiz9cn265kYGDrKUOjmyT5atm9xb1asKUFcJ71efdadb",
                Currency: localStorage.getItem("currency"),
              },
            }
          );
          // setProductsData({ products: res.data.results });
          setProductsData((prevData) => ({
            ...prevData,
            products: [...prevData.products, ...res.data.data.products],
          }));
        } catch (e) {
          console.log("error", e);
        } finally {
          setLoading(false);
        }
      }
    }
  }, [loading, page, value]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    // <div className="productPage">
    <>
      <Dialog
        open={isCountryOpen}
        onClose={() => setCountryOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent style={{ height: "100vh" }}>
          <Autocomplete
            disablePortal
            open
            options={countriesArr}
            fullWidth
            onChange={(even, value) => {
              setSelectedCountry(value);
              setCountryOpen(false);
            }}
            filterOptions={(options, { inputValue }) =>
              options.filter((el) => {
                console.log(el.name + el.native);
                return (el.name + el.native).toLowerCase().includes(inputValue);
              })
            }
            getOptionLabel={(option) => option.native}
            autoHighlight
            style={{ border: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option?.name === value?.name
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                gap={1}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.native} ({option.phone})
              </Box>
            )}
            renderInput={(params) => (
              <TextField style={{ border: "none" }} {...params} />
            )}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isAddContactOpen}
        onClose={() => setAddContact(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          textAlign={"center"}
          fontSize={14}
        >{t`Please enter your phone number`}</DialogTitle>
        <Box
          display={"flex"}
          alignItems={"center"}
          p={2}
          gap={1}
          flexDirection={"row"}
        >
          <Button color="inherit" onClick={() => setCountryOpen(true)}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${selectedCountry?.code?.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${selectedCountry?.code?.toLowerCase()}.png`}
                alt=""
              />
              <Typography>+{selectedCountry?.phone}</Typography>
            </Box>
          </Button>
          <TextField
            fullWidth
            type="text"
            id="phone_number"
            name="phone_number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={`${t`Phone number`} *`}
            required
          />
        </Box>
        <AddButton
          onClick={() => {
            localStorage.setItem(
              "phoneNumber",
              selectedCountry.phone + phoneNumber
            );
            setAddContact(false);
          }}
        >{t`Add`}</AddButton>
      </Dialog>

      <Container style={{ maxWidth: "87%" }}>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <p className="products-subcategory">
            {productsData.subcategory}{" "}
            <span className="products-count">
              {totalProducts || 0} {t`PRODUCTS`}
            </span>
          </p>
          <div>
            <SearchInput
              onChange={handleSearchInput}
              type="text"
              placeholder="Search"
            />
          </div>
        </Box>

        <Grid
          container
          gap={1.5}
          // alignItems={"center"}
          // justifyContent={"center"}
          mt={5}
        >
          {Array.isArray(productsData.products) &&
            productsData.products.map((product, index) => (
              <Grid key={product.id} xs={5.7} md={2.9}>
                <ProductCard
                  key={product.id}
                  onAddToCart={(p) => handleAddToCart(p)}
                  addToCart={true}
                  product={{ ...product, images: product.images }}
                  index={index}
                  variationsData={productsData?.variations}
                  selectedItem={selectedItem}
                  onSelectItem={async (item) => {
                    setProductsData((prev) => ({
                      ...prev,
                      variations: null,
                    }));
                    setSelectedItem(null);
                    try {
                      setAddToCartLoading(true);
                      const response = await service.get(
                        `variations/${product.parent_id}`,
                        {
                          headers: {
                            "Accept-Language": i18n.language || "en",
                          },
                        }
                      );
                      setProductsData((prev) => ({
                        ...prev,
                        variations: response.data.data.Products,
                      }));
                      setSelectedItem(item);
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    } finally {
                      setAddToCartLoading(false);
                    }
                  }}
                  addToCartLoading={addToCartLoading}
                  handleSelectedProduct={(p) => setSelectedProduct(p)}
                />
              </Grid>
            ))}
        </Grid>

        {!loading && !productsData?.products && (
          <Box
            height={productsData?.products?.length > 0 ? "100%" : "50vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <h2>{t`Search for name, color, or category`}</h2>
          </Box>
        )}
        {loading && (
          <Box
            height={productsData?.products?.length > 0 ? "100%" : "50vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={35} style={{ color: "#000" }} />
          </Box>
        )}
      </Container>
    </>
  );
}

export default SearchPage;
