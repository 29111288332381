import { FC, ReactNode, createContext, useEffect, useReducer } from "react";
import { authApi } from "../core/services/auth";
import PropTypes from "prop-types";

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accessToken: null,
  error: "",
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, accessToken } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      accessToken,
    };
  },
  LOGIN: (state, action) => {
    const { user, accessToken } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      accessToken,
      error: "",
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    accessToken: null,
  }),
  DELETE: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    accessToken: null,
  }),
  ERROR: (state, action) => {
    const { error } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user: null,
      accessToken: null,
      error,
    };
  },
  REGISTER: (state, action) => {
    const { user, accessToken } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      accessToken,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  deleteAccount: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          const user = window.localStorage.getItem("user");

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: JSON.parse(user),
              accessToken: accessToken,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
              accessToken: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            accessToken: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const uuid = localStorage.getItem("cart_uuid");
      const result = await authApi.Login({ email, password, cart_uuid: uuid });
      localStorage.setItem("user", JSON.stringify(result.data.data.user));
      localStorage.setItem("accessToken", result.data.data.token);

      dispatch({
        type: "LOGIN",
        payload: {
          user: result.data.data.user,
          accessToken: result.data.data.token,
        },
      });
      window.location.href = "/";
    } catch (e) {
      if (e?.response?.data?.message) {
        dispatch({
          type: "ERROR",
          payload: {
            error:
              e?.response?.data?.message ??
              "User not found with the given email and password",
          },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: {
            error: "Server error",
          },
        });
      }
    }
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.reload();
    dispatch({ type: "LOGOUT" });
  };

  const deleteAccount = async () => {
    try {
      const result = await authApi.Delete();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      window.location.href = "/";
      dispatch({ type: "LOGOUT" });

      dispatch({
        type: "DELETE",
      });
      window.location.href = "/";
    } catch (e) {
      if (e?.response?.data?.message) {
        dispatch({
          type: "ERROR",
          payload: {
            error: e?.response?.data?.message ?? "Unexpected error",
          },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: {
            error: "Server error",
          },
        });
      }
    }
  };

  const register = async (req) => {
    try {
      const uuid = localStorage.getItem("cart_uuid");
      const result = await authApi.Register({
        ...req,
        cart_uuid: uuid,
      });
      localStorage.setItem("user", JSON.stringify(result.data.data.user));
      localStorage.setItem("accessToken", result.data.data.token);

      dispatch({
        type: "REGISTER",
        payload: {
          user: result.data.data.user,
          accessToken: result.data.data.token,
        },
      });
      window.location.href = "/";
    } catch (e) {
      if (e?.response?.data?.message) {
        dispatch({
          type: "ERROR",
          payload: {
            error:
              e?.response?.data?.message ??
              "User not found with the given email and password",
          },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: {
            error: "Server error",
          },
        });
      }
    }
    // const accessToken = await authApi.register({ email, name, password });
    // const user = await authApi.me(accessToken);
    // localStorage.setItem('accessToken', accessToken);
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user
    //   }
    // });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        deleteAccount,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
