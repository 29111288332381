import React, { useState, useEffect, useTransition } from "react";
import axios from "axios";
import "../HomePage/home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionOne from "./Sections/Section1/section1.jsx";
import SectionTwo from "./Sections/Section2/section2.jsx";
import SectionThree from "./Sections/Section3/section3.jsx";
import SectionFour from "./Sections/Section4/section4.jsx";
import News from "./News/news.jsx";
import { useTranslation } from "react-i18next";
import { service } from "../../core/services/index.js";

function Home() {
  const [banners, setBanners] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await service.get("bannerone", {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setBanners(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchBanners();
  }, [i18n.language]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="banner-container">
      <div className="carousel">
        <Slider {...settings}>
          {Array.isArray(banners) &&
            banners.map((item, index) => (
              <div key={index} className="carousel-item">
                {item.image && (
                  <a href={`/products/${item.id}`}>
                    <div className="banner-image-name">
                      <p className="banner-name">{item.name}</p>
                      <img src={item.image} alt="" className="banner-image" />
                    </div>
                  </a>
                )}
                {item.video && (
                  <a href={`/products/${item.id}`}>
                    <div className="banner-video-name">
                      <p className="banner-name">{item.name}</p>
                      <video
                        playsInline
                        autoPlay
                        loop
                        muted
                        className="banner-video"
                      >
                        <source src={item.video} type="video/mp4" />
                      </video>
                    </div>
                  </a>
                )}
              </div>
            ))}
        </Slider>
      </div>

      <div>
        <SectionOne />
      </div>

      <div>
        <SectionTwo />
      </div>

      <div>
        <SectionThree />
      </div>

      <div>
        <SectionFour />
      </div>

      <div>
        <News />
      </div>
    </div>
  );
}

export default Home;
