import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../News/news.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { service } from "../../../core/services";

const NewsAbsoluteText = styled.p`
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  font-size: 30px;
  left: -80px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  @media (max-width: 720px) {
    display: none;
  }
`;

const NewsRegularText = styled.p`
  display: none;
  color: #fff;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  font-weight: bold;
  @media (max-width: 720px) {
    display: inline-block;
  }
`;

function News() {
  const [news, setNews] = useState([]);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await service.get("news", {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setNews(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchNews();
  }, [i18n.language]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="section-news">
      <NewsAbsoluteText>Kyveli____News</NewsAbsoluteText>
      <Slider {...settings}>
        {Array.isArray(news) &&
          news.map((item, index) => (
            <div>
              <div key={index} className="news-item">
                {item.image && (
                  <div className="news-image-name">
                    <img src={item.image} alt="" className="news-image" />
                    <NewsRegularText>Kyveli____News</NewsRegularText>
                    <p className="news-name">{item.name}</p>
                    <button className="discover">{t`Discover`}</button>
                  </div>
                )}
                {item.video && (
                  <div className="news-video-name">
                    <video
                      autoPlay
                      playsInline
                      loop
                      muted
                      className="news-video"
                    >
                      <source src={item.video} type="video/mp4" />
                    </video>
                    <NewsRegularText>Kyveli____News</NewsRegularText>
                    <p className="news-name">{item.name}</p>
                    <button className="discover">{t`Discover`}</button>
                  </div>
                )}
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}
export default News;
