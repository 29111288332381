import { createSlice } from "@reduxjs/toolkit";
import { categoryApi } from "../services/categories";
import { productsApi } from "../services/products";

const initialState = {
  productsData: {},
  states: [],
  page: 1,
  status: "nothing",
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts(state, { payload }) {
      if (payload.page === 1) {
        state.productsData = payload;
      } else {
        state.productsData = {
          ...state.productsData,
          products: [...state.productsData.products, ...payload.products],
          subcategory: payload.subcategory,
        };
        // setProductsData((prevData) => ({
        //   ...prevData,
        //   products: [...prevData.products, ...newData.products],
        //   subcategory: newData.subcategory,
        // }));
      }
    },
    clearProducts(state, { payload }) {
      state.productsData = {};
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setProducts, setStatus, clearProducts } = slice.actions;

export const getProductsByCategoryAsync =
  (query, callback) => async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const response = await productsApi.GetProductsByCategory(query);
      const newData = response.data.data;
      dispatch(
        setProducts({
          products: newData.products,
          product_count: newData.product_count,
          subcategory: newData.subcategory,
          page: query.page,
        })
      );
      dispatch(setStatus("data"));
    } catch (e) {
      dispatch(setStatus("error"));
      console.log(e);
      // notificationEvent.emit("error", e.message);
    }
  };

export const productSlice = slice.reducer;
