import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./contactus.css";
import { Dialog, Drawer } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function ContactUs({ open, onClose }) {
  const { companyInformation } = useSelector((state) => state.company);
  const { t } = useTranslation();
  return (
    <Drawer open={open} onClose={onClose}>
      <div className="contact-us-page">
        {/* <div className="contact-black-column"></div> */}
        <div className="contact-white-column">
          <div className="content">
            <div className="times-title">
              <h3 className="contact-title">{t`Contact us`}</h3>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                className="contact-times"
              />
            </div>

            <div className="contact-ways">
              <div className="contact-phone">
                <FontAwesomeIcon icon={faPhone} />
                <a
                  href={`tel:${companyInformation?.phone_number}`}
                  className="span-contact"
                >
                  {t`Call Us`} {companyInformation?.phone_number}
                </a>
                <p className="p-contact">
                  {t`Our Client advisors are available to answer your Calls`}.
                </p>
              </div>

              <div className="contact-wp">
                <FontAwesomeIcon icon={faWhatsapp} />
                <a
                  href={`https://wa.me/${companyInformation?.wp_number}`}
                  className="span-contact"
                >
                  {t`Whatsapp Us`}{" "}
                </a>
                <p className="p-contact">
                  {t`Our Client advisors are available to answer your Whatsapp messages`}
                  .
                </p>
              </div>

              <div className="contact-email">
                <FontAwesomeIcon icon={faEnvelope} />
                <a
                  href={`mailto:${companyInformation?.email}`}
                  className="span-contact"
                >
                  {t`Email Us`}{" "}
                </a>
                <p className="p-contact">
                  {t`Our Client advisors are available to answer your Emails`}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ContactUs;
