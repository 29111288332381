import { service } from ".";

class CategoryApi {
  GetCategories() {
    return service.get(`category`);
  }
  GetSubCategories(subcategoryId) {
    return service.get(`products/${subcategoryId}`);
  }
}

export const categoryApi = new CategoryApi();
