import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { service } from "../../../../core/services";

function SectionThree() {
  const [sectionThree, setSectionThree] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchSectionThree = async () => {
      try {
        const response = await service.get("sectionthree", {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setSectionThree(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSectionThree();
  }, [i18n.language]);

  const handleItemClick = (id) => {
    window.location.href = `/products/${id}`;
  };
  return (
    <div className="sectionone">
      <div className="">
        {sectionThree && (
          <div className="section1-image-name">
            <a
              onClick={() => handleItemClick(sectionThree.id)}
              className="a-sections"
            >
              <p className="section1-name">{sectionThree.name}</p>
              <img
                src={sectionThree.image}
                alt={sectionThree.name}
                className="section1-image"
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default SectionThree;
