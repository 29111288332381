import React, { useState, useEffect } from "react";
import axios from "axios";
import "../CookiePolicy/cookiePolicy.css";
import { Grid } from "@mui/material";
import { service } from "../../core/services";
function CookiePolicy() {
  const [policyName, setPolicyName] = useState([]);

  const getcookiepolicy = async () => {
    try {
      const response = await service.get("cookie-policy");
      setPolicyName(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getcookiepolicy();
  }, []);

  return (
    <Grid container className="" justifyContent={"center"}>
      <Grid item mt={5} mb={5} xs={11} md={11}>
        {policyName.map((item, index) => (
          <div className="" key={index}>
            <div
              dangerouslySetInnerHTML={{ __html: item.content }}
              className="cookie-content"
            />
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

export default CookiePolicy;
