import { Box, Button, Step, Stepper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";

const StepButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  white-space: nowrap;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: transparent;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  &:is(.active) {
    font-weight: 800;
    color: #000;
    border-bottom: 1px solid #000;
  }
`;

const AppStepper = ({
  steps,
  activeStep,
  setActiveStep,
  completed,
  setCompleted,
}) => {
  const { i18n } = useTranslation();
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        style={{ overflowX: "scroll" }}
        connector={
          i18n.language === "ar" ? (
            <MdKeyboardArrowLeft
              size={29}
              style={{ color: "rgba(0,0,0,0.3)" }}
            />
          ) : (
            <MdKeyboardArrowRight
              size={29}
              style={{ color: "rgba(0,0,0,0.3)" }}
            />
          )
        }
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton
              disabled={!completed[index]}
              onClick={() => setActiveStep(index)}
              className={activeStep === index ? "active" : ""}
            >
              {index + 1}. {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default AppStepper;
