import { createSlice } from "@reduxjs/toolkit";
import { countryApi } from "../services/country";

const initialState = {
  countries: [],
  states: [
    { id: 2150, name: "Beirut" },
    { id: 2151, name: "Jabal Lubnan" },
    { id: 2152, name: "Mohafazat Liban-Nord" },
    { id: 2153, name: "Mohafazat Mont-Liban" },
    { id: 2154, name: "Sidon" },
    { id: 2155, name: "al-Biqa" },
    { id: 2156, name: "al-Janub" },
    { id: 2157, name: "an-Nabatiyah" },
    { id: 2158, name: "ash-Shamal" },
  ],
  status: "nothing",
};

const slice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountry(state, { payload }) {
      state.countries = payload;
    },
    setStates(state, { payload }) {
      state.states = payload;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setCountry, setStatus, setStates } = slice.actions;

export const getCountries = () => async (dispatch) => {
  try {
    dispatch(setStatus("loading"));
    const response = await countryApi.Get();
    dispatch(setCountry(response?.data?.data));
    dispatch(setStatus("data"));
  } catch (e) {
    dispatch(setStatus("error"));
    // notificationEvent.emit("error", e.message);
  }
};

export const countryReducer = slice.reducer;
