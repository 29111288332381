import { service } from ".";

class ProductsApi {
  GetProductsByCategory({ categoryId, page, sort, filters }) {
    console.log(filters);
    let colorParam = filters?.colors
      ?.map((el, i) => `colors[${i}]=${el}`)
      .join("&");

    let sizeParam = filters?.sizes
      ?.map((el, i) => `sizes[${i}]=${el}`)
      .join("&");
    return service.get(
      `products/${categoryId}?${
        filters?.min_price && `&min_price=${filters?.min_price}`
      }${
        filters?.max_price && `&max_price=${filters?.max_price}`
      }&page=${page}&sort=${sort}&${colorParam}&${sizeParam}`
    );
  }
}

export const productsApi = new ProductsApi();
