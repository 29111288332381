import { createSlice } from "@reduxjs/toolkit";
import { categoryApi } from "../services/categories";

const initialState = {
  categories: [],
  states: [],
  status: "nothing",
};

const slice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategories(state, { payload }) {
      state.categories = payload;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setCategories, setStatus } = slice.actions;

export const getCategoriesAsync = () => async (dispatch) => {
  try {
    dispatch(setStatus("loading"));
    const response = await categoryApi.GetCategories();
    dispatch(setCategories(response?.data?.data));
    dispatch(setStatus("data"));
  } catch (e) {
    dispatch(setStatus("error"));
    // notificationEvent.emit("error", e.message);
  }
};

export const categorySlice = slice.reducer;
