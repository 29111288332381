import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Section1/section1.css";
import { useTranslation } from "react-i18next";
import { service } from "../../../../core/services";

function SectionOne() {
  const [sectionOne, setSectionOne] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchSectionOne = async () => {
      try {
        const response = await service.get("/sectionone", {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setSectionOne(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSectionOne();
  }, [i18n.language]);

  return (
    <>
      <div className="sectionone">
        <div className="">
          {sectionOne && (
            <div className="section1-image-name">
              {sectionOne.image && (
                <a href={`/products/${sectionOne.id}`} className="a-sections">
                  <p className="section1-name">{sectionOne.name}</p>
                  <img
                    src={sectionOne.image}
                    alt={sectionOne.name}
                    className="section1-image"
                  />
                </a>
              )}
              {sectionOne.video && (
                <a href={`/products/${sectionOne.id}`} className="a-sections">
                  <p className="section1-name">{sectionOne.name}</p>
                  <video
                    playsInline
                    autoPlay
                    loop
                    muted
                    className="banner-video"
                  >
                    <source src={sectionOne.video} type="video/mp4" />
                  </video>
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SectionOne;
