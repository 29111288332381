import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Badge,
  DialogTitle,
  Dialog,
  CircularProgress,
} from "@mui/material";
import "../Navbar/navbar.css";
import logo from "../../Assets/logo.png";
// import arabicLogo from "../../Assets/arabi/logoArabi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faBars,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CartDrawer from "./CartDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import styled from "styled-components";
import { getCategoriesAsync } from "../../core/slices/categorySlice";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { service } from "../../core/services";
import MoreOptionButton from "./MoreOptionButton";

const MenuActionButton = styled(Button)`
  background-color: #000 !important;
  color: #fff !important;
  width: 100%;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`;

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setDesktopMenuOpen] = useState(false);
  const [showSubcategory, setShowSubcategory] = useState(null);
  const [openCart, setOpenCart] = useState(false);
  const isMouseOver = useRef(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const subcategoryTimer = useRef(null);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);

  const [anchorProfileEl, setAnchorProfileEl] = useState(false);
  const profileOpen = Boolean(anchorProfileEl);

  const { isAuthenticated, logout, deleteAccount } = useAuth();
  const { i18n } = useTranslation();
  const { cart } = useSelector((state) => state.cart);

  const handleProfileClick = (event) => {
    setAnchorProfileEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorProfileEl(null);
  };

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDesktopMenu = () => {
    setDesktopMenuOpen(!isDesktopMenuOpen);
  };

  const handleMouseEnter = (category) => {
    isMouseOver.current = true;
    setShowSubcategory(category);
  };

  const handleMouseLeave = () => {
    isMouseOver.current = false;
    subcategoryTimer.current = setTimeout(() => {
      if (!isMouseOver.current) {
        setShowSubcategory(null);
      }
    }, 200);
  };

  const handleSubcategoryClick = async (subcategory) => {
    try {
      const response = await service.get(`products/${subcategory.id}`);

      window.location.href = `/products/${subcategory.id}`;
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <div className="nav">
      {/* <Drawer
        open={isMobileMenuOpen}
        anchor="bottom"
        onClose={() => setMobileMenuOpen(false)}
      >
        {DrawerList}
      </Drawer> */}

      <MobileMenu
        open={isMobileMenuOpen}
        categories={categories}
        onClose={() => setMobileMenuOpen(false)}
      />

      <DesktopMenu
        open={isDesktopMenuOpen}
        categories={categories}
        onClose={toggleDesktopMenu}
      />

      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setDeleteModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          textAlign={"center"}
          fontSize={14}
        >{t`Are you sure you want to delete your account?`}</DialogTitle>
        <Box
          display={"flex"}
          alignItems={"center"}
          p={2}
          gap={1}
          flexDirection={"row"}
          mt={5}
        >
          <MenuActionButton
            onClick={async () => {
              setDeleteLoading(true);
              await deleteAccount();
              setDeleteLoading(false);
            }}
          >
            {isDeleteLoading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              t`Yes`
            )}
          </MenuActionButton>
          <MenuActionButton onClick={() => setDeleteModal(false)}>
            {t`No`}
          </MenuActionButton>
        </Box>
        {/* <AddButton
          onClick={() => {
            localStorage.setItem(
              "phoneNumber",
              selectedCountry.phone + phoneNumber
            );
            setAddContact(false);
          }}
        >{t`Add`}</AddButton> */}
      </Dialog>

      <CartDrawer openCart={openCart} onClose={() => setOpenCart(false)} />

      <div className="navbar">
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          <i className="desktop-menu" onClick={toggleDesktopMenu}>
            <FontAwesomeIcon icon={faBars} />
          </i>
          <a href="/">
            <img src={logo} alt="logo" className="logo" />
          </a>
        </Box>
        <div className="navbar-content">
          <ul
            // className={`categories ${
            //   isMobileMenuOpen ? "mobile-menu-open" : ""
            // }`}
            className={`categories`}
          >
            {categories?.map((category) => (
              <li
                key={category.id}
                className="category-subcategory"
                onMouseEnter={() => handleMouseEnter(category.name)}
                onMouseLeave={handleMouseLeave}
              >
                <span style={{ fontWeight: 700 }}>{category.name}</span>

                {showSubcategory === category.name &&
                  category.subcategories && (
                    <div
                      className={`subcategory-container ${
                        isMobileMenuOpen ? "mobile-menu-open" : ""
                      }`}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          padding: 5,
                          marginBottom: 10,
                          fontWeight: "bold",
                          borderBottom: "1px solid #000",
                        }}
                      >
                        <a
                          href={`/products/${category.id}`}
                          style={{
                            color: "#000",
                            textDecoration: "none",
                          }}
                        >
                          {t`View All`}
                        </a>
                      </div>

                      <div
                        style={{
                          // paddingTop: "20px",
                          // paddingBottom: "20px",
                          // display: "flex",
                          // justifyContent: "center",
                          columnCount: 5,
                          // textAlign: "center",
                        }}
                      >
                        {category?.subcategories?.map((subcategory) => (
                          <a
                            key={subcategory.id}
                            className="subcategory-name"
                            style={{ color: "inherit", textDecoration: "none" }}
                            href={`/products/${subcategory.id}`}
                          >
                            <p className="sub-name">{subcategory.name}</p>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
              </li>
            ))}
          </ul>
        </div>
        <div style={{ flex: 1 }} />

        <div className="icons">
          <MoreOptionButton />
          <Link to="/search">
            <i className="icon">
              <FontAwesomeIcon style={{ color: "#000" }} icon={faSearch} />
            </i>
          </Link>

          {isAuthenticated ? (
            <>
              <div>
                <div
                  id="basic-button"
                  aria-controls={profileOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  variant="text"
                  style={{ color: "#000" }}
                  aria-expanded={profileOpen ? "true" : undefined}
                  onClick={handleProfileClick}
                >
                  <i className="icon">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="user"
                      color="#000"
                    />
                  </i>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorProfileEl}
                  open={profileOpen}
                  onClose={handleProfileClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <div style={{ padding: 15 }}>
                    <p className="p-text-bold" style={{ textAlign: "center" }}>
                      {t`Are you sure you want to to logout?`}
                    </p>
                    <MenuItem>
                      <MenuActionButton
                        onClick={logout}
                      >{t`LOGOUT`}</MenuActionButton>
                    </MenuItem>

                    <MenuItem>
                      <MenuActionButton
                        onClick={() => setDeleteModal(true)}
                      >{t`DELETE ACCOUNT`}</MenuActionButton>
                    </MenuItem>
                  </div>
                </Menu>
              </div>
            </>
          ) : (
            <Link to="/login">
              <i className="icon">
                <FontAwesomeIcon icon={faUser} className="user" color="#000" />
              </i>
            </Link>
          )}

          {/* <i className="icon">
            <FaHeart />
          </i> */}

          <i className="icon" onClick={() => setOpenCart(true)}>
            <Badge badgeContent={cart?.carts?.length} style={{ color: "red" }}>
              <FontAwesomeIcon
                fontSize={20}
                style={{ color: "#000" }}
                icon={faBagShopping}
              />
            </Badge>
          </i>
          <i className="menu-icon" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={faBars} />
          </i>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
