import "./App.css";

import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from "react-redux";

import Layout from "./Components/layout";
import Home from "./Pages/HomePage/home";
import Login from "./Pages/Login/login";
import Signup from "./Pages/Signup/signup";
import Header from "./Pages/header";
import ProductPage from "./Pages/ProductPage/productPage";
import SingleProductPage from "./Pages/SingleProductPage";
import useLocalizeDocumentAttributes from "./i18n/useLocalizeDocumentAttributes";
import { AuthProvider } from "./contexts/JWTAuthcontext";
import CheckoutPage from "./Pages/CheckoutPage/Checkout";
import SearchPage from "./Pages/SearchPage/SearchPage";
import { useCallback, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ThankyouPage from "./Pages/ThankyouPage/ThankyouPage";
import styled from "styled-components";
import { Box } from "@mui/material";
import { addToQueueAsync } from "./core/slices/snackbarSlice";
import TermsAndConditions from "./Pages/TermsAndConditions/termsAndConditions";
import CookiePolicy from "./Pages/CookiePolicy/cookiePolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import { getCompanyInformationAsync } from "./core/slices/companySlice";
import { service } from "./core/services";

const SnackBar = styled(Box)`
  display: flex;
  padding: 10px 50px;
  position: fixed;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 3000;

  transition: all 1s;

  &:is(.snackbar-active) {
    transform: translateY(0px);
  }

  &:is(.snackbar-inactive) {
    transform: translateY(-100px);
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SnackText = styled.p`
  color: #fff;
  font-weight: 700;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

function App() {
  useLocalizeDocumentAttributes();
  const { messages } = useSelector((state) => state.snackbar);
  const { companyInformation } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const fetchCountry = useCallback(async () => {
    try {
      const res = await axios.get("http://ip-api.com/json");
      // const previews = localStorage.getItem("country");
      localStorage.setItem("country", res.data.countryCode);
      let url;
      // setTimeout(() => {
      url =
        localStorage.getItem("global") === "true"
          ? "https://lb-app.kyveli.me/api/"
          : localStorage.getItem("country") === "IQ"
          ? "https://lb-app.kyveli.me/api/"
          : "https://app.kyveli.me/api/";
      // }, 200);
      service.defaults.baseURL = url;

      return url;
      // if (!previews) window.location.reload();
    } catch (e) {
      console.log(e);
    }
  }, []);

  // useLayoutEffect(() => {
  // fetchCountry();
  // }, [fetchCountry]);

  useEffect(() => {
    // fetchCountry();
    if (service.defaults.baseURL) dispatch(getCompanyInformationAsync());
  }, [dispatch]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ld471EjAAAAAGBA7uc9BlmTr9s-3zBZHXFYzPGU">
      <SnackBar
        className={
          messages?.length > 0 && messages[0]
            ? "snackbar-active"
            : "snackbar-inactive"
        }
      >
        <SnackText>{messages?.length > 0 && messages[0].text}</SnackText>
      </SnackBar>
      <Box position={"fixed"} bottom={40} right={30} zIndex={2000}>
        <a
          href={`https://wa.me/${companyInformation?.wp_number}`}
          target="_blank"
          rel="noreferrer"
          style={{ cursor: "pointer" }}
        >
          <img
            src="https://logos-world.net/imageup/WhatsApp/WhatsApp-Logo-PNG11.png"
            width={80}
            alt="whatsapp"
          />
        </a>
      </Box>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/products/:categoryId" element={<ProductPage />} />
              <Route
                path="/products/product/:productId"
                element={<SingleProductPage />}
              />
              <Route path="/search" element={<SearchPage />} />
            </Route>

            <Route path="/" element={<Header />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Signup />} />
              <Route path="/thank-you" element={<ThankyouPage />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-conditions"
                element={<TermsAndConditions />}
              />
            </Route>
            <Route path="/checkout" element={<CheckoutPage />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
