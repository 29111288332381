import React, { useState } from "react";
import Select from "react-select";
import foot from "../../../Assets/Foot.svg";
import "../Size Guide/sizeguide.css";
import { Box, Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function SizeGuideShoes({ sizeOpen, onClose }) {
  const { companyInformation } = useSelector((state) => state.company);

  const { t, i18n } = useTranslation();
  const options = [
    { value: "Italy", label: "Italy" },
    { value: "France", label: "France" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "Germany", label: "Germany" },
    { value: "United States", label: "United States" },
    { value: "Brazil", label: "Brazil" },
    { value: "Japan", label: "Japan" },
    { value: "Korea, republic of", label: "Korea, republic of" },
    { value: "China", label: "China" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[2]);
  const [unit, setUnit] = useState("cm");

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const tableData = {
    Italy: [
      "Italy",
      34,
      34.5,
      35,
      35.5,
      36,
      36.5,
      37,
      37.5,
      38,
      38.5,
      39,
      39.5,
      40,
      40.5,
      41,
      41.5,
      42,
    ],
    France: [
      "France",
      35,
      35.5,
      36,
      36.5,
      37,
      37.5,
      38,
      38.5,
      39,
      39.5,
      40,
      40.5,
      41,
      41.5,
      42,
      42.5,
      43,
    ],
    "United Kingdom": [
      "United Kingdom",
      1,
      1.5,
      2,
      2.5,
      3,
      3.5,
      4,
      4.5,
      5,
      5.5,
      6,
      6.5,
      7,
      7.5,
      8,
      8.5,
      9,
    ],
    Germany: [
      "Germany",
      35,
      35.5,
      36,
      36.5,
      37,
      37.5,
      38,
      38.5,
      39,
      39.5,
      40,
      40.5,
      41,
      41.5,
      42,
      42.5,
      43,
    ],
    "United States": [
      "United States",
      4,
      4.5,
      5,
      5.5,
      6,
      6.5,
      7,
      7.5,
      8,
      8.5,
      9,
      9.5,
      10,
      10.5,
      11,
      11.5,
    ],
    Brazil: [
      "Brazil",
      33,
      33.5,
      34,
      34.5,
      35,
      35.5,
      36,
      36.5,
      37,
      37.5,
      38,
      38.5,
      39,
      39.5,
      40,
      40.5,
    ],
    Japan: [
      "Japan",
      21,
      21.5,
      22,
      22.5,
      23,
      23.5,
      24,
      24.5,
      25,
      25.5,
      26,
      26.5,
      27,
      27.5,
      28,
      28.5,
    ],
    "Korea, republic of": [
      "Korea, republic of",
      210,
      215,
      220,
      225,
      230,
      235,
      240,
      245,
      250,
      255,
      260,
      265,
      270,
      275,
      280,
      285,
    ],
    China: [
      "China",
      34,
      34.5,
      35,
      35.5,
      36,
      36.5,
      37,
      37.5,
      38,
      38.5,
      39,
      39.5,
      40,
      40.5,
      41,
      41.5,
      42,
    ],
  };

  const measurementsCm = {
    foot: [
      22, 22.3, 22.6, 23, 23.3, 23.6, 24, 24.3, 24.6, 25, 25.3, 25.6, 26, 26.3,
      26.6, 27, 27.3,
    ],
  };

  const convertToInches = (cm) => (cm * 0.393701).toFixed(2);

  const measurements =
    unit === "cm"
      ? measurementsCm
      : {
          foot: measurementsCm.foot.map(convertToInches),
        };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      border: "1px solid black",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
      borderColor: state.isFocused ? "black" : provided.borderColor,
      "&:hover": {
        borderColor: "black",
      },
      height: "50px",
      borderRaduis: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: state.isSelected
        ? "grey"
        : state.isFocused
        ? "darkgrey"
        : "grey",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "none",
      overflow: "visible",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black", // arrow color
      "&:hover": {
        color: "black",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "black", // vertical line color
    }),
  };

  return (
    <Dialog open={sizeOpen} onClose={onClose}>
      <div className="size-guide-page">
        <div className="black-column"></div>
        <div className="white-column">
          <div
            className="content"
            style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
          >
            <Box display={"flex"}>
              <IconButton style={{ marginLeft: "auto" }} onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <h4>{t`Select conversion table`}</h4>
            <Select
              options={options}
              styles={customStyles}
              onChange={handleOptionChange}
              defaultValue={selectedOption}
            />
            <div className="ready-radiobuttons">
              <h4>{t`Shoes`}</h4>
              <div>
                <input
                  type="radio"
                  id="cm"
                  value="cm"
                  name="radio-name"
                  checked={unit === "cm"}
                  onChange={handleUnitChange}
                />
                <label htmlFor="cm" className="labels">
                  cm
                </label>
                <input
                  type="radio"
                  id="inches"
                  value="inches"
                  name="radio-name"
                  checked={unit === "inches"}
                  onChange={handleUnitChange}
                />
                <label htmlFor="inches" className="labels">
                  inches
                </label>
              </div>
            </div>

            <div class="size-shoes-table-container">
              <table className="size-table">
                <thead>
                  <tr>
                    <th>{t`Kyveli size (IT)`}</th>
                    {tableData["Italy"].slice(1).map((size, index) => (
                      <td key={index}>{size}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {selectedOption &&
                      tableData[selectedOption.value].map((size, index) => (
                        <td key={index}>{size}</td>
                      ))}
                  </tr>
                  <tr>
                    <th>{t`Foot`}</th>
                    {measurements.foot.map((value, index) => (
                      <td key={index}>
                        {value} {unit === "cm" ? "cm" : "in"}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="">
              <h4>{t`Measurements guide`}</h4>
              <p className="measurements">
                {t`Measurements refer to the length of the foot, from heel to toe`}
                .
              </p>
              <div className="center-container">
                <img
                  src={foot}
                  className="body-img"
                  alt="Body measurement guide"
                />
              </div>
            </div>

            <div>
              <h4>{t`Contact us`}</h4>
              <p className="for-info">
                {t`For information about products and online orders, please visit the FAQ section or contact our Client Service. You can also get help by filling out the form in the Contact Us section`}
                .
              </p>
            </div>

            <div className="call-us-phone">
              <p className="call-us">{t`Call Us`} </p>
              <p className="phone-call">{companyInformation?.phone_number}</p>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SizeGuideShoes;
