import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  getShippingMethodsAsync,
  setSelectedShippingMethod,
} from "../../core/slices/checkoutSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  addToCartByUuidAsync,
  decrementFromCartAsync,
  removeFromCartAsync,
} from "../../core/slices/cartSlice";

const ShippingMethod = ({
  stateId,
  // setSelectedShippingMethod,
  // selectedShippingMethod,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { companyInformation } = useSelector((state) => state.company);
  const { cart, addToCartStatus } = useSelector((state) => state.cart);
  const { personalInformation } = useSelector((state) => state.company);

  const { shippingMethods, selectedShippingMethod, status } = useSelector(
    (state) => state.checkout
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  // const [shippingMethodsData, setShippingMethodsDta] = useState(null);

  // const fetchShiipingMethod = useCallback(async () => {
  //   try {
  //     const res = await service.get(
  //       "shipping-rule/" + stateId + "/" + localStorage.getItem("cart_uuid")
  //     );
  //     setShippingMethodsDta(res.data.data);
  //     setSelectedShippingMethod(res.data.data?.shipping_rules[0]);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [setSelectedShippingMethod, stateId]);

  // useEffect(() => {
  //   fetchShiipingMethod();
  // }, [fetchShiipingMethod]);

  useEffect(() => {
    dispatch(getShippingMethodsAsync(stateId));
  }, [dispatch, stateId]);

  const handleIncrement = (item) => {
    dispatch(
      addToCartByUuidAsync(
        {
          product_id: item.product_id,
          quantity: 1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {
          dispatch(getShippingMethodsAsync(personalInformation?.state?.id));
        }
      )
    );
  };

  const handleDecrement = (item) => {
    if (item.quantity <= 1) {
      handleRemove(item);
      dispatch(getShippingMethodsAsync(personalInformation?.state?.id));
      return;
    }
    dispatch(
      decrementFromCartAsync(
        {
          product_id: item.product_id,
          quantity: -1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {
          dispatch(getShippingMethodsAsync(personalInformation?.state?.id));
        }
      )
    );
  };

  const handleRemove = (item) => {
    dispatch(removeFromCartAsync(item.id));
  };

  if (status === "loading")
    return (
      <Box
        width={"100%"}
        height={200}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress style={{ color: "#000" }} size={30} />
      </Box>
    );

  return (
    <div className="" style={{ padding: 20 }}>
      <h2
        className="email-h2"
        style={{
          fontSize: 17,
          marginBottom: 40,
        }}
      >{t`Shipping Methods`}</h2>
      {smallDevice && (
        <Box mb={5}>
          {cart?.carts?.map((item) => (
            <Box
              display={"flex"}
              gap={2}
              mb={1}
              flexDirection={"row"}
              //   alignItems={"flex-end"}
            >
              <img
                src={item?.image[0]}
                className="products-image"
                style={{ width: 90 }}
                alt={item.product_name}
              />
              <Box display={"flex"} flexDirection={"column"}>
                <Box mb={"auto"}>
                  <p className="p-text-bold">{item.product_name}</p>
                  <p className="p-text">
                    {item.color}, {item.size}
                  </p>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"row"}
                  gap={1}
                >
                  <IconButton
                    size="small"
                    onClick={() => handleDecrement(item)}
                  >
                    {addToCartStatus === "loading" || status === "loading" ? (
                      <CircularProgress style={{ color: "#000" }} size={18} />
                    ) : (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </IconButton>
                  <p className="p-text">
                    {t`Qty`}: {item.quantity}
                  </p>
                  <IconButton
                    size="small"
                    onClick={() => handleIncrement(item)}
                  >
                    {addToCartStatus === "loading" || status === "loading" ? (
                      <CircularProgress style={{ color: "#000" }} size={18} />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <p
                className="p-text"
                style={{ marginLeft: "auto", marginTop: "auto" }}
              >
                {item.computed_price}{" "}
                {localStorage?.getItem("currency") ??
                  companyInformation?.default_currency}
              </p>
            </Box>
          ))}
        </Box>
      )}
      {shippingMethods?.shipping_rules?.map((rule) => (
        <Box
          display={"flex"}
          alignItems={"center"}
          pl={1}
          pr={1}
          style={{ cursor: "pointer" }}
          border={"1px solid #000"}
          mb={2}
          onClick={() => dispatch(setSelectedShippingMethod(rule))}
        >
          <Box
            mr={1}
            border={"2px solid #000"}
            width={30}
            height={30}
            borderRadius={60}
            p={0.5}
          >
            {selectedShippingMethod?.id === rule.id && (
              <Box
                bgcolor={"#000"}
                borderRadius={60}
                width={"100%"}
                height={"100%"}
              />
            )}
          </Box>
          <p className="p-text">{rule?.name}</p>
          <p className="p-text-bold" style={{ marginLeft: "auto" }}>
            {rule?.price}{" "}
            {localStorage?.getItem("currency") ??
              companyInformation?.default_currency}
          </p>
        </Box>
      ))}

      <Box mt={4} display={"flex"} justifyContent={"flex-end"}>
        <button
          className="register-button"
          style={{ width: 200 }}
          onClick={onConfirm}
        >
          {t`Confirm`}
        </button>
      </Box>
    </div>
  );
};

export default ShippingMethod;
