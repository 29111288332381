import React, { useState } from "react";
import Select from "react-select";
import body from "../../../Assets/Body_2.svg";
import "../Size Guide/sizeguide.css";
import { Box, Dialog, Drawer, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function SizeGuide({ sizeOpen, onClose }) {
  const { t, i18n } = useTranslation();
  const { companyInformation } = useSelector((state) => state.company);

  const options = [
    { value: "Italy", label: "Italy" },
    { value: "France", label: "France" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "Germany", label: "Germany" },
    { value: "United States", label: "United States" },
    { value: "Brazil", label: "Brazil" },
    { value: "Japan", label: "Japan" },
    { value: "Korea, republic of", label: "Korea, republic of" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[2]);
  const [unit, setUnit] = useState("cm");

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const tableData = {
    Italy: ["Italy", 36, 38, 40, 42, 44, 46, 48, 50],
    France: ["France", 32, 34, 36, 38, 40, 42, 44, 46],
    "United Kingdom": ["United Kingdom", 33, 44, 55, 66, 77, 88, 99, "-"],
    Germany: ["Germany", 30, 32, 34, 36, 38, 40, 42, 44],
    "United States": ["United States", 0, 2, 4, 6, 8, 10, 12, 14],
    Brazil: [
      "Brazil",
      "XPP (34)",
      "PP (36)",
      "P (38)",
      "M (40)",
      "M (42)",
      "G (44)",
      "G (46)",
      "GG (48)",
    ],
    Japan: ["Japan", 5, 7, 9, 11, 13, 15, 17, 19],
    "Korea, republic of": [
      "Korea, republic of",
      33,
      44,
      55,
      66,
      77,
      88,
      99,
      "-",
    ],
  };

  const measurementsCm = {
    chest: [80.4, 84.4, 88, 92, 96, 100, 104, 108],
    waist: [59, 62.8, 66, 70, 74, 78, 82, 84],
    hips: [86, 89.8, 93, 97, 101, 105, 109, 113],
  };

  const convertToInches = (cm) => (cm * 0.393701).toFixed(2);

  const measurements =
    unit === "cm"
      ? measurementsCm
      : {
          chest: measurementsCm.chest.map(convertToInches),
          waist: measurementsCm.waist.map(convertToInches),
          hips: measurementsCm.hips.map(convertToInches),
        };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      border: "1px solid black",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
      borderColor: state.isFocused ? "black" : provided.borderColor,
      "&:hover": {
        borderColor: "black",
      },
      height: "50px",
      borderRaduis: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: state.isSelected
        ? "grey"
        : state.isFocused
        ? "darkgrey"
        : "grey",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "none",
      overflow: "visible",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black", // arrow color
      "&:hover": {
        color: "black",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "black", // vertical line color
    }),
  };

  return (
    <Drawer open={sizeOpen} onClose={onClose}>
      <div className="size-guide-page">
        <div className="black-column"></div>
        <div className="white-column">
          <div
            className="content"
            style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
          >
            <Box display={"flex"}>
              <IconButton style={{ marginLeft: "auto" }} onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <h4>{t`Select conversion table`}</h4>
            <Select
              options={options}
              styles={customStyles}
              onChange={handleOptionChange}
              defaultValue={selectedOption}
            />
            <div className="ready-radiobuttons">
              <h4>{t`Ready to wear`}</h4>
              <div>
                <input
                  type="radio"
                  id="cm"
                  value="cm"
                  name="radio-name"
                  checked={unit === "cm"}
                  onChange={handleUnitChange}
                />
                <label htmlFor="cm" className="labels">
                  cm
                </label>
                <input
                  type="radio"
                  id="inches"
                  value="inches"
                  name="radio-name"
                  checked={unit === "inches"}
                  onChange={handleUnitChange}
                />
                <label htmlFor="inches" className="labels">
                  inches
                </label>
              </div>
            </div>

            <div class="size-table-container">
              <table className="size-table">
                <thead>
                  <tr>
                    <th>{t`Kyveli size (IT)`}</th>
                    {tableData["Italy"].slice(1).map((size, index) => (
                      <td key={index}>{size}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {selectedOption &&
                      tableData[selectedOption.value].map((size, index) => (
                        <td key={index}>{size}</td>
                      ))}
                  </tr>
                  <tr>
                    <th>{t`Chest`}</th>
                    {measurements.chest.map((value, index) => (
                      <td key={index}>
                        {value} {unit === "cm" ? "cm" : "in"}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>{t`Waist`}</th>
                    {measurements.waist.map((value, index) => (
                      <td key={index}>
                        {value} {unit === "cm" ? "cm" : "in"}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>{t`Hips`}</th>
                    {measurements.hips.map((value, index) => (
                      <td key={index}>
                        {value} {unit === "cm" ? "cm" : "in"}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
              <p className="garments">
                {t`Garments in the sizes marked "S" are 5 cm shorter in length than the standard size`}
                .
              </p>
            </div>

            <div className="">
              <h4>{t`Measurements guide`}</h4>
              <p className="measurements">
                {t`Measurements refer to the circumference`}.
              </p>
              <div className="center-container">
                <img
                  src={body}
                  className="body-img"
                  alt="Body measurement guide"
                />
              </div>
            </div>

            <div>
              <h4>{t`Contact us`}</h4>
              <p className="for-info">
                {t`For information about products and online orders, please visit the FAQ section or contact our Client Service. You can also get help by filling out the form in the Contact Us section`}
                .
              </p>
            </div>

            <div className="call-us-phone">
              <p className="call-us">{t`Call Us`} </p>

              <p className="phone-call">{companyInformation?.phone_number}</p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default SizeGuide;
