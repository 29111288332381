import axios from "axios";

const getBaseURL = async () => {
  if (localStorage.getItem("global") === "true") {
    if (!localStorage.getItem("currency")) {
      const response = await axios.get(
        "https://lb-app.kyveli.me/api/footer-details"
      );
      localStorage.setItem("currency", response?.data?.data?.default_currency);
    }
    return "https://lb-app.kyveli.me/api/";
  } else {
    try {
      //   const res = await axios.get("http://ip-api.com/json");
      //   // const previews = localStorage.getItem("country");
      //   localStorage.setItem("country", res.data.countryCode);
      //   let url = "";

      //   switch (res.data.countryCode) {
      //     case "QA":
      //     case "BH":
      //     case "OM":
      //     case "KW":
      //     case "SA":
      //     case "AE":
      //       url = "https://uae-app.kyveli.me/api";
      //       break;

      //     // case "JO":
      //     //   url = "jd-app.kyveli.me";
      //     //   break;

      //     default:
      //       url = "https://app.kyveli.me/api/";
      //       break;
      //   }

      //   return localStorage.getItem("global") === "true"
      //     ? "https://lb-app.kyveli.me/api/"
      //     : url;
      // } catch (e) {
      //   return "https://app.kyveli.me/api/";
      // }

      const res = await axios.get("https://lb-app.kyveli.me/api/check-ip");
      // localStorage.setItem("country", res.data.countryCode);
      if (!localStorage.getItem("currency")) {
        const response = await axios.get(res.data.url + "footer-details");
        localStorage.setItem(
          "currency",
          response?.data?.data?.default_currency
        );
      }
      return res.data.url;
    } catch (e) {
      if (!localStorage.getItem("currency")) {
        const response = await axios.get(
          "https://lb-app.kyveli.me/api/footer-details"
        );
        localStorage.setItem(
          "currency",
          response?.data?.data?.default_currency
        );
      }
      return "https://lb-app.kyveli.me/api/";
    }
  }
};

export const service = axios.create({
  baseURL: await getBaseURL(),
});

service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    config.headers["Currency"] = localStorage.getItem("currency");
    config.headers["Accept-Language"] = localStorage.getItem("i18nextLng");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
