import { createSlice } from "@reduxjs/toolkit";
// import { notificationEvent } from "../events/notification";
import { checkoutApi } from "../services/checkout";

const initialState = {
  shippingMethods: [],
  selectedShippingMethod: {},
  status: "nothing",
};

const slice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setShippingMethods(state, { payload }) {
      state.shippingMethods = payload;
    },
    setSelectedShippingMethod(state, { payload }) {
      state.selectedShippingMethod = payload;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setStatus, setShippingMethods, setSelectedShippingMethod } =
  slice.actions;

export const getShippingMethodsAsync = (stateId) => async (dispatch) => {
  try {
    dispatch(setStatus("loading"));
    const response = await checkoutApi.GetShippingMethods(stateId);
    dispatch(setShippingMethods(response?.data?.data));
    dispatch(setSelectedShippingMethod(response?.data.data?.shipping_rules[0]));

    dispatch(setStatus("data"));
  } catch (e) {
    dispatch(setStatus("error"));
    // notificationEvent.emit("error", e.message);
  }
};

export const checkoutReducer = slice.reducer;
