// import { countries } from "countries-list";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, setStates } from "../../core/slices/countrySlice";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { countries } from "countries-list";
import { service } from "../../core/services";
import { useFormik } from "formik";

// const countryOptions = Object.keys(countries).map((countryCode) => ({
//   value: countryCode,
//   label: `${countries[countryCode].name} (+${countries[countryCode].phone})`,
//   phoneCode: `+${countries[countryCode].phone}`,
// }));

const countriesArrWithIs = Object.keys(countries).map((country) => ({
  ...countries[country],
  code: country,
}));

const countriesArr = countriesArrWithIs.filter(
  (el) => el.name.toLowerCase() !== "israel"
);

const ContactInformation = ({ onFinish, data }) => {
  const { t } = useTranslation();
  const [isCountryOpen, setCountryOpen] = useState(false);
  const { user, accessToken } = useAuth();
  const { countries, states, status } = useSelector((state) => state.country);
  const { companyInformation } = useSelector((state) => state.company);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Lebanon",
    native: "لبنان",
    phone: [961],
    continent: "AS",
    capital: "Beirut",
    currency: ["LBP"],
    languages: ["ar", "fr"],
    code: "LB",
  });

  useEffect(() => {
    if (companyInformation?.currency === "AED") {
      setSelectedCountry({
        name: "United Arab Emirates",
        native: "دولة الإمارات العربية المتحدة",
        phone: [971],
        continent: "AS",
        capital: "Abu Dhabi",
        currency: ["AED"],
        languages: ["ar"],
        code: "AE",
      });
    }
  }, [companyInformation?.currency]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    onSubmit: (values) => {
      onFinish(values);
    },
  });
  const dispacth = useDispatch();

  useEffect(() => {
    dispacth(getCountries());
  }, [dispacth]);

  // useEffect(() => {
  //   if (countries) {
  //     formik.setFieldValue("country", countries[0]);
  //     dispacth(setStates(countries[0].states));
  //   }
  // }, [countries]);

  return (
    <>
      <Dialog
        open={isCountryOpen}
        onClose={() => setCountryOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent style={{ height: "100vh" }}>
          <Autocomplete
            disablePortal
            open
            options={countriesArr}
            fullWidth
            onChange={(even, value) => {
              setSelectedCountry(value);
              setCountryOpen(false);
            }}
            filterOptions={(options, { inputValue }) =>
              options.filter((el) => {
                console.log(el.name + el.native);
                return (el.name + el.native).toLowerCase().includes(inputValue);
              })
            }
            getOptionLabel={(option) => option.native}
            autoHighlight
            style={{ border: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option?.name === value?.name
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                gap={1}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.native} ({option.phone})
              </Box>
            )}
            renderInput={(params) => (
              <TextField style={{ border: "none" }} {...params} />
            )}
          />
        </DialogContent>
      </Dialog>

      <div className="" style={{ padding: 20 }}>
        <h2
          className="email-h2"
          style={{
            fontSize: 17,
            marginBottom: 40,
          }}
        >{t`Contact information`}</h2>
        <p style={{ fontSize: 14 }} className="register-p">
          {t`Please enter your e-mail address, if you already have an account, your order will be added to that account. If you don't have account, you can proceed as a guest and choose to complete your registration after checkout`}
        </p>

        <form
          onSubmit={formik.handleSubmit}
          //  onSubmit={handleRegisterSubmit}
          className="form-register"
        >
          <div className="register-div-fname-lname">
            <input
              type="text"
              id="fname"
              name="email"
              value={formik.values.email}
              placeholder={t`E-mail address *`}
              className="fname"
              onChange={formik.handleChange}
              required
            />
          </div>

          <h2
            className="email-h2"
            style={{ fontSize: 17, marginBottom: 20, marginTop: 20 }}
          >{t`Shipping Address`}</h2>

          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            alignItems={"center"}
            gap={{ xs: 1, md: 3 }}
          >
            <div className="register-div-fname-lname">
              <input
                type="text"
                id="fname"
                name="fname"
                placeholder={t`First Name *`}
                className="fname"
                value={formik.values.fname}
                onChange={formik.handleChange}
                //   onChange={handleNameChange}
                required
              />
            </div>
            <div className="register-div-fname-lname">
              <input
                type="text"
                id="lname"
                name="lname"
                placeholder={t`Last Name *`}
                className="lname"
                value={formik.values.lname}
                onChange={formik.handleChange}
                //   onChange={handleNameChange}
                required
              />
            </div>
          </Box>

          <div className="register-country-phone">
            <div className="country-select" style={{ flex: 1 }}>
              <ReactSelect
                options={countries}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
                id="countryCode"
                // isDisabled={status === "loading"}
                isLoading={status === "loading"}
                name={"country"}
                value={formik.values.country}
                onChange={(o) => {
                  formik.setFieldValue("country", o);
                  dispacth(setStates(o.states));
                }}
                placeholder="Select country"
                classNamePrefix="react-select"
                className="country-code"
              />
            </div>
          </div>

          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            alignItems={"center"}
            gap={3}
          >
            <div className="register-country-phone">
              <div className="country-select" style={{ flex: 1 }}>
                <ReactSelect
                  options={states}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  name={"state"}
                  id="countryCode"
                  onChange={(o) => {
                    console.log(o);
                    formik.setFieldValue("state", o);
                  }}
                  value={formik.values.state}
                  // value={selectedCountry}
                  // onChange={setSelectedCountry}
                  placeholder="Select State"
                  classNamePrefix="react-select"
                  className="country-code"
                />
              </div>
            </div>
            <div className="register-div-fname-lname">
              <input
                type="text"
                id="lname"
                name="district"
                value={formik.values.district}
                placeholder={t`District`}
                className="lname"
                onChange={formik.handleChange}
              />
            </div>
          </Box>

          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            alignItems={"center"}
            gap={{ xs: 1, md: 3 }}
          >
            <div className="register-div-fname-lname">
              <input
                type="text"
                id="lname"
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                placeholder={t`Street *`}
                className="lname"
                required
              />
            </div>
            <div className="register-div-fname-lname">
              <input
                type="text"
                id="lname"
                name="building"
                value={formik.values.building}
                placeholder={t`Building *`}
                onChange={formik.handleChange}
                className="lname"
                required
              />
            </div>
          </Box>

          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            alignItems={"center"}
            gap={{ xs: 1, md: 3 }}
          >
            <div className="register-div-fname-lname">
              <input
                type="text"
                id="lname"
                name="apartment"
                value={formik.values.apartment}
                placeholder={t`Apartment *`}
                className="lname"
                onChange={formik.handleChange}
                required
              />
            </div>

            {formik.values.country.has_zipcode && (
              <div className="register-div-fname-lname">
                <input
                  type="text"
                  id="lname"
                  name="nearestLandmark"
                  value={formik.values.nearestLandmark}
                  placeholder={t`ZIP code *`}
                  className="lname"
                  onChange={formik.handleChange}
                  required
                />
              </div>
            )}
          </Box>

          <div
            className="register-country-phone"
            style={{ alignItems: "center", padding: 5 }}
          >
            <div className="country-select" style={{ padding: 5 }}>
              <Button color="inherit" onClick={() => setCountryOpen(true)}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${selectedCountry?.code?.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${selectedCountry?.code?.toLowerCase()}.png`}
                    alt=""
                  />
                  <Typography>+{selectedCountry?.phone}</Typography>
                </Box>
              </Button>
            </div>
            <span className="cross" style={{ margin: 0 }}>
              |
            </span>
            {/* <TextField
              // fullWidth
              type="text"
              id="phone_number"
              name="phone_number"
              // value={phoneNumber}
              // onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={`${t`Phone number`} *`}
              required
            /> */}
            <input
              type="text"
              id="phoneNumber"
              placeholder="Phone number *"
              className="phone-input"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </div>

          {!accessToken && (
            <Box mb={5}>
              <input
                type="checkbox"
                id="agree"
                name="register"
                // onChange={handleCheckboxChange}
                className="agreement"
              />
              <label htmlFor="agree">
                <span className="custom-checkbox"></span>
                <span className="label-agree">
                  {t`Register an account with above information`}
                </span>
              </label>
            </Box>
          )}

          <Box display={"flex"} justifyContent={"flex-end"}>
            <button
              className="register-button"
              // type="submit"
              style={{ width: 200 }}
              // onClick={async () => {
              //   try {
              //     const res = await service.get("store", {
              //       cart_uuid: localStorage.getItem("cart_uuid"),
              //       first_name: "Mand",
              //       last_name: "Lawand",
              //       email: "mendd.ali@gmail.com",
              //       phone: user.phone,
              //       country: 213,
              //       state: 2151,
              //       city: "Beirut",
              //       currency: "USD",
              //       payment_channel: "COD",
              //       street: "dsk",
              //       building: "Dsd",
              //       apartment: "2324",
              //       nearest: "dskj",
              //       shipping_rule_id: 2,
              //       note: "test",
              //       site_key: "6Ld2OlEpAAAAAKFNJm16n9-6NBNzC7HoAv8tqQiU",
              //     });
              //     console.log(res);
              //   } catch (e) {
              //     console.log(e);
              //   }
              // }}
            >
              {/* {loading ? (
          <CircularProgress style={{ color: "#fff" }} />
        ) : ( */}
              {t`Confirm`}
              {/* )} */}
            </button>
          </Box>
        </form>
      </div>
    </>
  );
};

export default ContactInformation;
