import { service } from ".";

class CheckoutApi {
  GetShippingMethods(stateId) {
    return service.get(
      "shipping-rule/" + stateId + "/" + localStorage.getItem("cart_uuid")
    );
  }
}

export const checkoutApi = new CheckoutApi();
