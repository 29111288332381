import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";

export const supportedLngs = {
  en: "English",
  ar: "Arabic (العربية)",
};

i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    //   lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    // fallbackLng: "en",

    resources: {
      en: {
        translations: require("./locales/en/translations.json"),
      },
      ar: {
        translations: require("./locales/ar/translations.json"),
      },
    },
    supportedLngs: Object.keys(supportedLngs),

    interpolation: {
      escapeValue: false,
    },

    ns: ["translations"],
    defaultNS: "translations",
  });

i18n.languages = ["en", "ar"];

export default i18n;
