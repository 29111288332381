import { service } from ".";

class CountryApi {
  Get() {
    return service.get(`country`);
  }
  GetStateByCountryName(countryName) {
    return service.get(`state/${countryName}`);
  }
}

export const countryApi = new CountryApi();
