import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { service } from "../../core/services";
import { useSelector } from "react-redux";

const BoldText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
`;

const RegularText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 18px;
`;

const AddButton = styled(Button)`
  background: #000 !important;
  display: inline-block;
  margin-top: 30px !important;
  color: #fff !important;
  padding: 15px !important;
  font-weight: 700 !important;
  &:hover {
    background: rgba(0, 0, 0, 0.9) !important;
  }
`;

const ThankyouPage = () => {
  const [order, setOrder] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const mediumDevice = useMediaQuery(theme.breakpoints.up("md"));
  const { companyInformation } = useSelector((state) => state.company);
  const [searchParams] = useSearchParams();

  const fetchOrder = useCallback(async () => {
    const token = searchParams.get("order-token");

    if (token) {
      try {
        setLoading(true);
        const res = await service.get("order/" + token);
        setOrder(res.data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  console.log(order);

  if (loading)
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
      >
        <CircularProgress style={{ color: "#000" }} size={50} />
      </Box>
    );

  if (!loading && !order) return <></>;

  return (
    <Grid container p={{ xs: 3, sm: 3, md: 5 }} gap={7}>
      <Grid item xs={12} sm={12} md={7}>
        <Box display={"flex"} alignItems={"center"} gap={3}>
          <Box
            bgcolor={"#000"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={60}
            p={1}
          >
            <CheckIcon
              style={{ color: "#fff", fontSize: 60, fontWeight: 700 }}
            />
          </Box>
          <Box>
            <BoldText>{t`Your order is successfully placed`}</BoldText>
            <RegularText>{t`Thank you for shopping with Kyveli , we are glad you are part of our family`}</RegularText>
          </Box>
        </Box>

        <Box mt={5} p={1} bgcolor={"rgba(0,0,0, 0.04)"}>
          <BoldText>{t`Customer information`}</BoldText>
          <Grid alignItems={"center"} mt={1} container gap={5}>
            <Grid item>
              <p className="p-text">{t`Full name`}:</p>
              <p className="p-text">{t`Phone`}:</p>
              <p className="p-text">{t`Email`}:</p>
              <p className="p-text">{t`Address`}:</p>
              <p className="p-text">{t`Shipping method`}:</p>
              <p className="p-text">{t`Payment method`}:</p>
              <p className="p-text">{t`Payment status`}:</p>
            </Grid>
            <Grid item>
              <p className="p-text" style={{ fontWeight: 700 }}>
                {order?.address?.name}
              </p>
              <p className="p-text" style={{ fontWeight: 700 }}>
                {order?.address?.phone}
              </p>
              <p className="p-text" style={{ fontWeight: 700 }}>
                {order?.address?.email}
              </p>
              <p className="p-text" style={{ fontWeight: 700 }}>
                {order?.address?.address}
              </p>
              <p className="p-text" style={{ fontWeight: 700 }}>
                {order?.shipping_method}
              </p>
              <p className="p-text" style={{ fontWeight: 700 }}>
                {order?.payment_method}
              </p>
              <p className="p-text" style={{ fontWeight: 700 }}>
                {order && order["payment-status"]}
              </p>
            </Grid>
          </Grid>
        </Box>

        <AddButton
          LinkComponent={Link}
          href="/"
        >{t`CONTINUE SHOPPING`}</AddButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        height={"100vh"}
        borderLeft={mediumDevice ? "1px solid rgba(0,0,0,0.15)" : "none"}
      >
        <Box p={2}>
          <BoldText style={{ marginBottom: 20 }}>{`${t`Order number`}: ${
            order?.order?.id
          }`}</BoldText>
          <hr color="lightgray" />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <p className="p-text">{t`Subtotal`}:</p>
            <p className="p-text-bold">
              {" "}
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}{" "}
              {order?.order?.sub_total}
            </p>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <p className="p-text">{t`Shipping Fee`}:</p>
            <p className="p-text-bold">
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}{" "}
              {order?.order?.shipping_amount}
            </p>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <p className="p-text">{t`Discount`}:</p>
            <p className="p-text-bold" style={{ color: "red" }}>
              -
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}{" "}
              {order?.order?.discount_amount}
            </p>
          </Box>

          <hr color="lightgray" />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <p className="p-text">{t`Total`}:</p>
            <p className="p-text-bold">
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}{" "}
              {order?.order?.amount}
            </p>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ThankyouPage;
