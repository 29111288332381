import { combineReducers } from "@reduxjs/toolkit";
import { cartReducer } from "../slices/cartSlice";
import { countryReducer } from "../slices/countrySlice";
import { categorySlice } from "../slices/categorySlice";
import { productSlice } from "../slices/productSlice";
import { snackbarReducer } from "../slices/snackbarSlice";
import { companyReducer } from "../slices/companySlice";
import { checkoutReducer } from "../slices/checkoutSlice";
// import { reducer as calendarReducer } from '@/core/slices/calendar';
// import { reducer as projectsBoardReducer } from '@/core/slices/projects_board';
// import { reducer as mailboxReducer } from '@/core/slices/mailbox';
// import { institutionReducer } from '../slices/institution';
// import { countryReducer } from '../slices/country';
// import { courseReducer } from '../slices/course';
// import { userReducer } from '../slices/user';
// import { customerReducer } from '../slices/customer';
// import { bonusReducer } from '../slices/Bonus';

export const rootReducer = combineReducers({
  cart: cartReducer,
  country: countryReducer,
  category: categorySlice,
  product: productSlice,
  snackbar: snackbarReducer,
  company: companyReducer,
  checkout: checkoutReducer,
  //   calendar: calendarReducer,
  //   projectsBoard: projectsBoardReducer,
  //   mailbox: mailboxReducer,
  //   institutionReducer,
  //   countryReducer,
  //   courseReducer,
  //   userReducer,
  //   customerReducer,
  //   bonusReducer
});
