import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { StarBorder } from "@mui/icons-material";
import { service } from "../../core/services";
import { useTranslation } from "react-i18next";

const DesktopMenu = ({ onClose, categories, open }) => {
  return (
    <Drawer open={open} onClose={onClose}>
      <Box width={400} role="presentation">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          sx={{ width: "100%", maxWidth: 400, bgcolor: "background.paper" }}
        >
          {categories?.map((category, i) => (
            <RenderCategoryItem
              openBydefault={i === 0}
              key={category.id}
              category={category}
            />
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

const RenderCategoryItem = ({ category, openBydefault }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openBydefault);

  return (
    <>
      <ListItem key={category?.id} disablePadding>
        <ListItemButton onClick={() => setOpen((prev) => !prev)}>
          {/* <ListItemText style={{ fontWeight: 700 }} primary={category?.name} /> */}
          <span style={{ fontWeight: 700 }}>{category?.name}</span>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <a
            href={`/products/${category.id}`}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <ListItemButton
              // onClick={() => handleSubcategoryClick(subcategories)}
              sx={{ pl: 4 }}
            >
              <ListItemText primary={t`View All`} />
            </ListItemButton>
          </a>
          {category?.subcategories.map((subcategories) => (
            <a
              href={`/products/${subcategories.id}`}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ListItemButton
                // onClick={() => handleSubcategoryClick(subcategories)}
                key={subcategories.id}
                sx={{ pl: 4 }}
              >
                <ListItemText primary={subcategories.name} />
              </ListItemButton>
            </a>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default DesktopMenu;
