import React, { useState, useEffect } from "react";
import axios from "axios";
import { service } from "../../core/services";
import { Grid } from "@mui/material";

function PrivacyPolicy() {
  const [TermsAndConditions, setTermsAndConditions] = useState([]);

  const getTerms = async () => {
    try {
      const response = await service.get("privacy-policy");
      setTermsAndConditions(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <Grid container className="" justifyContent={"center"}>
      <Grid item mt={5} mb={5} xs={11} md={11}>
        {TermsAndConditions.map((item, index) => (
          <div className="terms-div" key={index}>
            <div
              dangerouslySetInnerHTML={{ __html: item.content }}
              className="cookie-content"
            />
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

export default PrivacyPolicy;
