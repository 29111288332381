import { createSlice } from "@reduxjs/toolkit";
// import { notificationEvent } from "../events/notification";
import { cartApi } from "../services/cart";
import { addToQueueAsync } from "./snackbarSlice";

const initialState = {
  cart: {
    carts: [],
    sub_total: 0,
  },
  status: "nothing",
  addToCartStatus: "nothing",
};

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    removeFromCart(state, { payload }) {
      state.cart.carts = state.cart.carts.filter((el) => el.id !== payload.id);
      state.cart.sub_total -= payload.computed_price;
    },
    addToCart(state, { payload }) {
      const index = state?.cart?.carts.findIndex((el) => el.id === payload.id);
      console.log(payload);
      if (index === -1) {
        state.cart.carts.push(payload);
        state.cart.sub_total += payload.product_price;
        state.cart["price-after-discount"] += payload.product_price;
      } else {
        state.cart.carts[index] = payload;
        state.cart.sub_total += payload.product_price;
        state.cart["price-after-discount"] += payload.product_price;
      }
    },
    decrementFromCart(state, { payload }) {
      const index = state?.cart?.carts.findIndex((el) => el.id === payload.id);
      if (index !== -1) {
        state.cart.carts[index] = payload;
        state.cart.sub_total -= payload.product_price;
        state.cart["price-after-discount"] -= payload.product_price;
      }
    },
    setCart(state, { payload }) {
      state.cart = payload;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
    setAddToCartStatus(state, { payload }) {
      state.addToCartStatus = payload;
    },
  },
});

export const {
  setCart,
  setStatus,
  addToCart,
  removeFromCart,
  setAddToCartStatus,
  decrementFromCart,
} = slice.actions;

export const getCartAsync =
  (promoCode, shippingPrice, stateId) => async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const uuid = localStorage.getItem("cart_uuid");
      const response = await cartApi.GetCartByUuid(
        uuid,
        promoCode,
        shippingPrice,
        stateId
      );
      dispatch(setCart(response?.data?.data));
      dispatch(setStatus("data"));
    } catch (e) {
      dispatch(setStatus("error"));
      // notificationEvent.emit("error", e.message);
    }
  };

export const addToCartByUuidAsync = (req, callback) => async (dispatch) => {
  try {
    dispatch(setAddToCartStatus("loading"));
    const uuid = localStorage.getItem("cart_uuid");

    const res = await cartApi.addToCartByUuid({ ...req, cart_uuid: uuid });
    if (!uuid) {
      localStorage.setItem("cart_uuid", res?.data?.data?.cart_uuid);
    }
    // dispatch(addToCart(res?.data?.data));
    dispatch(getCartAsync());
    dispatch(
      addToQueueAsync({ text: "Item seccessfuly added to cart", id: 1 })
    );
    dispatch(setAddToCartStatus("data"));
    callback();
  } catch (e) {
    console.log(e);
    dispatch(
      addToQueueAsync({
        text: e.response?.data?.message ?? "Server error",
        id: 1,
      })
    );

    dispatch(setAddToCartStatus("error"));
    callback();
  }
};

export const decrementFromCartAsync = (req, callback) => async (dispatch) => {
  try {
    dispatch(setAddToCartStatus("loading"));
    const uuid = localStorage.getItem("cart_uuid");

    const res = await cartApi.addToCartByUuid({ ...req, cart_uuid: uuid });
    if (!uuid) {
      localStorage.setItem("cart_uuid", res?.data?.data?.cart_uuid);
    }
    // dispatch(decrementFromCart(res?.data?.data));
    dispatch(getCartAsync());
    dispatch(setAddToCartStatus("data"));
    callback();
  } catch (e) {
    console.log(e);
    dispatch(
      addToQueueAsync({
        text: e.response?.data?.message ?? "Server error",
        id: 1,
      })
    );

    dispatch(setAddToCartStatus("error"));
    callback();
  }
};

export const addToCartWithAuthenticationAsync =
  (req, callback) => async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      const res = await cartApi.addToCart(req);
      console.log(res);
      localStorage.setItem("cart_uuid", res.data.data.cart_uuid);
      dispatch(addToCart(res?.data?.data));
      dispatch(setStatus("data"));
      dispatch(
        addToQueueAsync({ text: "Item seccessfuly added to cart", id: 1 })
      );
      callback();
    } catch (e) {
      console.log(e);
      dispatch(
        addToQueueAsync({
          text: e.response?.data?.message ?? "Server error",
          id: 1,
        })
      );
      dispatch(setStatus("error"));
      callback();
    }
  };

export const removeFromCartAsync = (itemId) => async (dispatch) => {
  try {
    dispatch(setStatus("loading"));
    const uuid = localStorage.getItem("cart_uuid");
    const res = await cartApi.deleteFromCartByUuid({
      uuid,
      itemId,
    });
    // dispatch(removeFromCart(res.data.data));
    dispatch(getCartAsync());
    dispatch(setStatus("data"));
  } catch (e) {
    dispatch(
      addToQueueAsync({
        text: e.response?.data?.message ?? "Server error",
        id: 1,
      })
    );
    console.log(e);
    dispatch(setStatus("error"));
  }
};

export const cartReducer = slice.reducer;
