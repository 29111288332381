import { createSlice } from "@reduxjs/toolkit";
// import { notificationEvent } from "../events/notification";
import { companyApi } from "../services/company";

const initialState = {
  companyInformation: {},
  status: "nothing",
};

const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setInformation(state, { payload }) {
      state.companyInformation = payload;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setStatus, setInformation } = slice.actions;

export const getCompanyInformationAsync = () => async (dispatch) => {
  try {
    dispatch(setStatus("loading"));
    const response = await companyApi.GetInformation();
    dispatch(setInformation(response?.data?.data));
    dispatch(setStatus("data"));
  } catch (e) {
    dispatch(setStatus("error"));
    // notificationEvent.emit("error", e.message);
  }
};

export const companyReducer = slice.reducer;
