import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Login/login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHeart,
  faPhone,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import styled from "styled-components";
import { service } from "../../core/services";

const ErrorText = styled.span`
  color: red;
  margin: 0;
  padding: 0;
  margin-bottom: -30px;
`;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSecondForm, setShowSecondForm] = useState(false);
  const [showFirstForm, setShowFirstForm] = useState(true);
  const { login, error: authError } = useAuth();

  const { t } = useTranslation();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const checkAccount = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    try {
      const response = await service.post("check-account", formData);
      const { data, message } = response.data;
      if (data === true) {
        setShowSecondForm(true);
        setShowFirstForm(false);
      } else {
        localStorage.setItem("email", email);
        window.location.href = "/register";
      }
    } catch (error) {
      console.log(error);
      setError("An error occurred while checking the account.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    await login(email, password);
    setLoading(false);
    // if (Boolean(authError)) {
    //   setLoading(false);
    // } else {
    //   setLoading(false);
    // }
  };

  return (
    <>
      <div className="login-center">
        {showFirstForm && (
          <div className="start">
            <h2 className="email-h2">{t`Enter your Email`}</h2>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t("E-mail address *")}
              className="email-input"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button className="next-button" onClick={checkAccount}>
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                t`Next`
              )}
            </button>
            <hr className="hr-email" />
          </div>
        )}

        {showSecondForm && (
          <div className="start">
            <h2 className="email-h2">Enter your Password</h2>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password *"
              className="email-input"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button className="next-button" onClick={handleLogin}>
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                t`Next`
              )}
            </button>
            {Boolean(authError) && <ErrorText>{authError}</ErrorText>}
            <hr className="hr-email" />
          </div>
        )}

        <div className="icons-login">
          <p className="p-text">
            {t`Enjoy a unique shopping experience with your personal account`}
          </p>
          <i className="icon-login">
            <FontAwesomeIcon icon={faUser} />{" "}
            <span className="text-icon">
              {" "}
              {t`Check the details and monitor the status of your orders and returns`}
            </span>
          </i>

          <i className="icon-login">
            <FontAwesomeIcon icon={faHeart} />
            <span className="text-icon">
              {" "}
              {t`Create a wishlist to save your favorite items`}
            </span>
          </i>

          <i className="icon-login">
            <FontAwesomeIcon icon={faBagShopping} />
            <span className="text-icon">
              {" "}
              {t`View your private appointments and repairs requests`}
            </span>
          </i>

          <i className="icon-login">
            <FontAwesomeIcon icon={faPhone} />
            <span className="text-icon">
              {" "}
              {t`Receive tailored assistance from our Client Service`}
            </span>
          </i>
        </div>
      </div>
    </>
  );
}

export default Login;
