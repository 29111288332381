import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Section2/section2.css";
import { useTranslation } from "react-i18next";
import { service } from "../../../../core/services";

function SectionTwo() {
  const [sectionTwo, setSectionTwo] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchSectionTwo = async () => {
      try {
        const response = await service.get("sectiontwo", {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setSectionTwo(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSectionTwo();
  }, [i18n.language]);

  const handleItemClick = (id) => {
    window.location.href = `/products/${id}`;
  };

  return (
    <>
      <div className="sectiontwo">
        {Array.isArray(sectionTwo) &&
          sectionTwo.map((item, index) => (
            <div className="sectiontwo-image-name" key={index}>
              <a
                onClick={() => handleItemClick(item.id)}
                className="a-sections"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="sectiontwo-image"
                />
                <p className="sectiontwo-name">{item.name}</p>
              </a>
            </div>
          ))}
      </div>
    </>
  );
}

export default SectionTwo;
